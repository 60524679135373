@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Yesteryear&display=swap');

@layer base {
  html {
    font-family: 'Poppins', sans-serif;
  }
}

@layer components {
  .heroButton {
    @apply px-6 py-2 uppercase text-xs tracking-widest text-orange-500 transition-all hover:text-blue-600
  }

  /* .label {
    @apply relative overflow-hidden rounded-md bg-slate-100 px-3 py-1 pb-1.5 text-[13px] font-semibold;
    @apply dark:bg-slate-800;

    &__indicator {
      @apply absolute inset-x-0 bottom-0 h-0.5 rounded-full bg-slate-300;
      @apply dark:bg-amber-600;
    }
  } */
}

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}


body {
  cursor:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="%23000000" stroke="%23000000" stroke-width="0.25" d="M5.5 3.21V20.8c0 .45.54.67.85.35l4.86-4.86a.5.5 0 0 1 .35-.15h6.87a.5.5 0 0 0 .35-.85L6.35 2.85a.5.5 0 0 0-.85.35Z"></path></svg>'),auto;
}

.dark body {
  cursor:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23FFFFFF" stroke-width="0.25" d="M5.5 3.21V20.8c0 .45.54.67.85.35l4.86-4.86a.5.5 0 0 1 .35-.15h6.87a.5.5 0 0 0 .35-.85L6.35 2.85a.5.5 0 0 0-.85.35Z"></path></svg>'),auto;
}


.hide-down-arrow {
  display: none;
}

html,
body {
  overflow-x: hidden;
}


::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}
::-webkit-scrollbar-track {
background: #0D0C0E; /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #474747; /* Color of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F59E0B; /* Color of the handle when hovered */
}

/* GitHub icon color */
.github-icon:hover {
  color: white;
}

/* LinkedIn icon color */
.linkedin-icon:hover {
  color: #0077b5; /* LinkedIn blue */
}

/* Instagram icon color */
.instagram-icon:hover {
  color: #E4405F; /* Instagram pink */
}

/* Email icon color */
.email-icon:hover {
  color: #D14836; /* A common email color */
}

img{
  user-select: none;
}